import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import Combatant from './Combatant';
import BattleSession from './BattleSession';
import ChallengerBattleSession from './ChallengerBattleSession';
import MapLocation from './MapLocation';
import SiteData from './SiteData';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import ResponsiveDrawer from './ResponsiveDrawer';
import FightTransition from './FightTransition';
import MyBattleSession from './MyBattleSession';
import UIStateEnum from './UIStateEnum'

import { Suspense, lazy } from 'react';

import { Provider } from 'react-redux'

import { createAppStore } from './store'


import { Route } from 'react-router-dom';
import {
  withRouter
} from 'react-router-dom'

import { connect } from 'react-redux'

import { IAppState } from './store/IAppState'
import { getOrCreateCombatant, getMyBattles, createNewBattleSession, getBattleSession, getChallengers, updateMyBattleSession } from './store/actions'

import { RouteComponentProps } from "react-router";

import MyBattlesView from './views/MyBattlesView';

const MyProfileView = lazy(() => import('./views/MyProfileView'));
const MapView = lazy(() => import('./views/MapView'));
const BattlerProfileView = lazy(() => import('./views/BattlerProfileView'));
const RankingsView = lazy(() => import('./views/RankingsView'));
const BattleView = lazy(() => import('./views/BattleView'));
const ShenanigansView = lazy(() => import('./views/ShenanigansView'));
const BattleResultView = lazy(() => import('./views/BattleResultView'));
const BattleChallengersView = lazy(() => import('./views/BattleChallengersView'));
const NotWorthyView = lazy(() => import('./views/NotWorthyView'));
const DisputesAdminView = lazy(() => import('./views/DisputesAdminView'));


const appStore = createAppStore()


const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

class Profile {
  currentPath = 'start'
}


interface IAppProps {
  combatant: Combatant | null
  myBattles: MyBattleSession[] | null
  currentBattleSession: BattleSession | null
  currentBattleSessionError: Error | null
  currentBattleChallengers: ChallengerBattleSession[] | null
  currentBattleChallengersError: Error | null
  currentBattleChallengersBattleId: string | null,
  getMyBattles: typeof getMyBattles
  getOrCreateCombatant: typeof getOrCreateCombatant
  createNewBattleSession: typeof createNewBattleSession
  getBattleSession: typeof getBattleSession
  getChallengers: typeof getChallengers
  updateMyBattleSession: typeof updateMyBattleSession
}

let siteData = new SiteData();

let mapLocations: Array<MapLocation> = [
  new MapLocation({ path: 'start', townName: 'Noob Town', x: '87%', y: '31%', adjacents: ['start/functions'], title: 'The Basics', recommendedNext: 'start/functions' }),
  new MapLocation({ path: 'start/functions', townName: 'Seaside Fun', x: '83%', y: '12%', adjacents: ['start', 'start/classes'], title: 'React Function Components', recommendedNext: 'start/classes' }),
  new MapLocation({ path: 'start/classes', townName: 'Classy Town', x: '60%', y: '15%', adjacents: ['start/functions', 'hooks'], title: 'React Class Components', recommendedNext: 'hooks' }),
  new MapLocation({ path: 'hooks', townName: 'Hooks Harbor', x: '60%', y: '34%', adjacents: ['start/classes', 'advanced'], title: 'React Hooks', recommendedNext: 'advanced' }),
  new MapLocation({ path: 'advanced', townName: 'Paradise Gate', x: '36.8%', y: '73%', adjacents: ['hooks', 'danger'], title: 'Advanced Topics', recommendedNext: 'danger' }),
  new MapLocation({ path: 'danger', townName: 'Danger Mountain', x: '7.5%', y: '85.6%', adjacents: ['advanced'], title: 'React Gotchas', recommendedNext: 'start/functions' }),
]

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

function App(props: RouteComponentProps & IAppProps) {
  let [smallScreen, setSmallScreen] = useState(false);
  let [firstRun, setFirstRun] = useState(!localStorage.getItem("localprofile"));

  let [currentPath, setCurrentPath] = useState('');

  let { currentBattleChallengers, myBattles, location, history, createNewBattleSession, combatant, getOrCreateCombatant, currentBattleSession, currentBattleChallengersBattleId, getBattleSession, getMyBattles, getChallengers } = props;

  let previousLocation = usePrevious(location);

  let [uiState, setUiState] = useState(UIStateEnum.LoadOrCreateCombatant);

  let [showFightDiv, setShowFightDiv] = useState(false);

  useEffect(() => {
    let handleNavRouteToState = function (currentView: string) {
      if (!currentView) {
        if (!myBattles) {
          setUiState(UIStateEnum.LoadMyBattles);
        }
        else {
          setUiState(UIStateEnum.MyBattles);
        }
      }
      else if (currentView === 'battle') {
        if (getFirstViewSegment(location.pathname)) {
          setUiState(UIStateEnum.LoadExistingBattle);
        }
        else {
          history.replace("");
        }
      }
      else if (currentView === 'challengers') {
        if (getFirstViewSegment(location.pathname)) {
          setUiState(UIStateEnum.LoadChallengers);
        }
        else {
          history.replace("");
        }
      }
      else if (currentView === "battle-result") {
        if (getFirstViewSegment(location.pathname)) {
          setUiState(UIStateEnum.LoadBattleResult);
        }
        else {
          history.replace("");
        }
      }
      else if (currentView === "profile") {
        setUiState(UIStateEnum.Profile);
      }
      else if (currentView === "not-worthy") {
        setUiState(UIStateEnum.NotWorthy);
      }
      else if (currentView === "map") {
        setUiState(UIStateEnum.Map);
      }
    }

    if (uiState === UIStateEnum.LoadOrCreateCombatant) {
      getOrCreateCombatant();
      setUiState(UIStateEnum.LoadingCombatant)
    }
    else if (uiState === UIStateEnum.LoadingCombatant) {
      let currentView = getCurrentView(location.pathname);
      if (firstRun && !currentView) {
        setUiState(UIStateEnum.StartBattle);
      }
      else {
        handleNavRouteToState(currentView);
      }
      if (firstRun) {
        setFirstRun(false);
      }
    }
    else if (combatant) {
      if (previousLocation && previousLocation.pathname !== location.pathname) {
        handleNavRouteToState(getCurrentView(location.pathname));
      }
      else if (uiState === UIStateEnum.LoadMyBattles) {
        getMyBattles(combatant.combatantId);
        setUiState(UIStateEnum.LoadingMyBattles);
      }
      else if (uiState === UIStateEnum.LoadingMyBattles) {
        if (myBattles) {
          setUiState(UIStateEnum.MyBattles);
        }
      }
      if (uiState === UIStateEnum.StartBattle) {
        createNewBattleSession(combatant.combatantId, currentPath, siteData.siteLanguage);
        setShowFightDiv(true);
        setUiState(UIStateEnum.StartingBattle);
      }
      else if (uiState === UIStateEnum.StartingBattle) {
        if (currentBattleSession) {
          setUiState(UIStateEnum.Battle);
          setShowFightDiv(false);
          history.push('battle/' + currentBattleSession.battleId);
        }
      }
      else if (uiState === UIStateEnum.LoadExistingBattle) {
        let battleId = getFirstViewSegment(location.pathname);
        getBattleSession(combatant.combatantId, battleId, siteData.siteLanguage);
        if (!currentBattleChallengers || currentBattleChallengers[0].battleId !== battleId) {
          getChallengers(combatant.combatantId, battleId);
        }
        setShowFightDiv(true);
        setUiState(UIStateEnum.LoadingExistingBattle);
      }
      else if (uiState === UIStateEnum.LoadingExistingBattle) {
        setUiState(UIStateEnum.Battle);
        setShowFightDiv(false);
      }
      else if (uiState === UIStateEnum.LoadBattleResult) {
        let battleId = getFirstViewSegment(location.pathname);
        getBattleSession(combatant.combatantId, battleId, siteData.siteLanguage);
        if (!currentBattleChallengers || currentBattleChallengers[0].battleId !== battleId) {
          getChallengers(combatant.combatantId, battleId);
        }
        setShowFightDiv(false);
        setUiState(UIStateEnum.LoadingBattleResult);
      }
      else if (uiState === UIStateEnum.LoadingBattleResult) {
        setUiState(UIStateEnum.BattleResult);
      }
      else if (uiState === UIStateEnum.LoadChallengers) {
        let battleId = getFirstViewSegment(location.pathname);
        if (!currentBattleChallengers || currentBattleChallengers[0].battleId !== battleId) {
          getChallengers(combatant.combatantId, battleId);
        }
        setUiState(UIStateEnum.LoadingChallengers);
      }
      else if (uiState === UIStateEnum.LoadingChallengers) {
        if (currentBattleChallengers) {
          setUiState(UIStateEnum.Challengers);
        }
      }
    }
  }, [currentBattleChallengers, myBattles, uiState, previousLocation, getMyBattles, createNewBattleSession, showFightDiv, firstRun, getOrCreateCombatant, getBattleSession, getChallengers, location, currentPath, combatant, currentBattleSession, history]);

useEffect(() => {
  if (!currentPath) {
    let localProfile: Profile;
    let localProfileString = localStorage.getItem('localprofile');
    if (!localProfileString || localProfileString === 'null' || localProfileString === 'undefined') {
      localProfile = new Profile();
      localStorage.setItem('localprofile', JSON.stringify(localProfile));
    }
    else {
      localProfile = JSON.parse(localProfileString);
    }
    setCurrentPath(localProfile.currentPath);
  }
  else {
    let localProfile: Profile;
    let localProfileString = localStorage.getItem('localprofile');
    if (!localProfileString || localProfileString === 'null' || localProfileString === 'undefined') {
      localProfile = new Profile();
      localStorage.setItem('localprofile', JSON.stringify(localProfile));
    }
    else {
      localProfile = JSON.parse(localProfileString);
      localProfile.currentPath = currentPath;
      localStorage.setItem('localprofile', JSON.stringify(localProfile));
    }
  }
}, [currentPath]);


useEffect(() => {
  let onHideFightDiv = function () {
    setShowFightDiv(false);
  }
  let onBattleSessionCompleted = function () {
    if (combatant) {
      getMyBattles(combatant.combatantId);
    }
  }
  let onLoadChallengers = function (e: { detail: { battleId: string; }; }) {
    if (currentBattleChallengersBattleId !== e.detail.battleId && combatant) {
      getChallengers(combatant.combatantId, e.detail.battleId);
    }
  }
  let onCreateNewBattle = function (e: { detail: { battlePath: string; }; }) {
    if (e.detail && e.detail.battlePath) {
      setCurrentPath(e.detail.battlePath);
      localStorage.setItem('localprofile', JSON.stringify({ currentPath: e.detail.battlePath }));
    }

    setUiState(UIStateEnum.StartBattle);
  }

  let onUpdateMapLocation = function(e: { detail: { path: string } }) {
    setCurrentPath(e.detail.path);
  };
  window.addEventListener('update-map-location', onUpdateMapLocation as unknown as EventListener);
  window.addEventListener('create-new-battle', onCreateNewBattle as unknown as EventListener);
  window.addEventListener('hide-fight-div', onHideFightDiv as unknown as EventListener);
  window.addEventListener('load-challengers', onLoadChallengers as unknown as EventListener);
  window.addEventListener('battle-session-completed', onBattleSessionCompleted as unknown as EventListener);

  const handler = (e: MediaQueryListEvent) => setSmallScreen(!e.matches);

  const mql = window.matchMedia("(min-width: 768px)");
  mql.addEventListener("change", handler);

  return () => {
    window.removeEventListener('hide-fight-div', onHideFightDiv);
    window.removeEventListener('battle-session-completed', onBattleSessionCompleted);
    window.removeEventListener('create-new-battle', onCreateNewBattle as unknown as EventListener);
    window.removeEventListener('load-challengers', onLoadChallengers as unknown as EventListener);
    window.removeEventListener('update-map-location', onUpdateMapLocation as unknown as EventListener);
  }
}, [combatant, uiState, showFightDiv, currentPath, currentBattleChallengersBattleId, getOrCreateCombatant, createNewBattleSession, getBattleSession, getChallengers, getMyBattles]);


function getCurrentTown(currentPath: string, mapLocations: MapLocation[]): MapLocation {
  let location = findMapLocation(currentPath);
  if (!location) {
    setCurrentPath("start");
    location = findMapLocation("start");
  }

  if (!location) {
    return new MapLocation();
  }
  return location;
}


return (
  <Provider store={appStore}>
    <div>
      <div hidden={props.currentBattleSessionError === null}>
        <span>Error starting battle, try again later!</span>
      </div>
      <div>
        (<div hidden={!showFightDiv}><FightTransition showFightDiv={showFightDiv} fightImage={siteData.fightImage} fightImageWidth={siteData.fightImageWidth} /></div>)
        <ThemeProvider theme={theme}>
          <ResponsiveDrawer combatant={props.combatant}>
            <div>
              <Route exact={true} path='/' render={() => (
                <div>
                  {props.combatant && <MyBattlesView myBattles={props.myBattles} myBattlesLoaded={props.myBattles != null} siteData={siteData} mapLocations={mapLocations} combatant={props.combatant} currentTown={getCurrentTown(currentPath, mapLocations)}></MyBattlesView>}
                </div>
              )} />

              <Route exact={true} path='/profile/:username' render={() => (
                <Suspense fallback={<div>Loading...</div>}>
                  <div>
                    {props.combatant && (<BattlerProfileView siteData={siteData} combatant={props.combatant} />)}
                  </div>
                </Suspense>
              )} />
              <Route exact={true} path='/profile' render={() => (
                <Suspense fallback={<div>Loading...</div>}>
                  {props.combatant && (<div>
                    <MyProfileView combatant={props.combatant} />
                  </div>)}
                </Suspense>
              )} />
              <Route exact={true} path='/map' render={() => (
                <Suspense fallback={<div>Loading...</div>}>
                  <div>
                    <MapView siteData={siteData} mapLocations={mapLocations} siteLanguage='React' />
                  </div>
                </Suspense>
              )} />
              <Route exact={true} path='/rankings' render={() => (
                <Suspense fallback={<div>Loading...</div>}>
                  <div>
                    <RankingsView />
                  </div>
                </Suspense>
              )} />
              <Route exact={true} path='/shenanigans' render={() => (
                <Suspense fallback={<div>Loading...</div>}>
                  <div>
                    <ShenanigansView />
                  </div>
                </Suspense>
              )} />


              <Route exact={true} path='/battle/:battleId' render={() => (
                <Suspense fallback={<div>Loading...</div>}>
                  <div>
                    {
                      props.combatant && props.currentBattleSession &&
                      (<BattleView smallScreen={smallScreen}
                        battleSession={props.currentBattleSession}
                        challengeSessions={props.currentBattleChallengers}
                        updateMyBattleSession={props.updateMyBattleSession}
                        currentTown={getCurrentTown(currentPath, mapLocations)}
                        mapLocations={mapLocations}
                        combatant={props.combatant} />)}
                  </div>
                </Suspense>
              )} />
              <Route exact={true} path='/not-worthy/:battleId/:username' render={() => (
                <Suspense fallback={<div>Loading...</div>}>
                  <div>
                    {
                      props.combatant && (<NotWorthyView combatant={props.combatant} />)}
                  </div>
                </Suspense>
              )} />
              <Route exact={true} path='/battle-result/:battleId' render={() => (
                <Suspense fallback={<div>Loading...</div>}>
                  <div>
                    {
                      props.combatant && (<BattleResultView smallScreen={smallScreen}
                        challengeSessions={props.currentBattleChallengers}
                        siteData={siteData}
                        combatant={props.combatant} />)}
                  </div>
                </Suspense>
              )} />
              <Route exact={true} path='/disputes-admin/:apiKey' render={() => (
                <Suspense fallback={<div>Loading...</div>}>
                  <div>
                      <DisputesAdminView />
                  </div>
                </Suspense>
              )}/>
              <Route exact={true} path='/challengers/:battleId' render={() => (
                <Suspense fallback={<div>Loading...</div>}>
                  <div>
                    {
                      props.combatant &&
                      (<BattleChallengersView smallScreen={smallScreen}
                        challengers={props.currentBattleChallengers}
                        combatant={props.combatant} />)}
                  </div>
                </Suspense>
              )} />


            </div>
          </ResponsiveDrawer>
        </ThemeProvider>
      </div>
    </div>
  </Provider>
);
}


function findMapLocation(path: string): MapLocation | null {
  for (let i = 0; i < mapLocations.length; i++) {
    if (mapLocations[i].path === path) {
      return mapLocations[i];
    }
  }
  return null;
}

function getCurrentView(path: string): string {
  return getPathSegment(path, 1);
}

function getPathSegment(path: string, segmentIndex: number) {
  let segs = path.split('/');
  if (segs.length > segmentIndex) {
    return segs[segmentIndex];
  }
  else {
    return '';
  }
}

function getFirstViewSegment(path: string) {
  return getPathSegment(path, 2);
}

function mapStateToProps(state: IAppState) {
  return {
    combatant: state.combatantState.combatant,
    myBattles: state.myBattlesState.myBattles,
    currentBattleSession: state.battleSessionState.battleSession,
    currentBattleSessionError: state.battleSessionState.battleSessionError,
    currentBattleChallengers: state.challengersState.challengers,
    currentBattleChallengersError: state.challengersState.challengersError,
    currentBattleChallengersBattleId: state.challengersState.challengersBattleId,
  }
}

export default withRouter(connect(mapStateToProps, { getOrCreateCombatant, getMyBattles, createNewBattleSession, getBattleSession, getChallengers, updateMyBattleSession })(App));
