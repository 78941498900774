import { fetchOrCreateCombatant } from './Combatant/FetchOrCreateCombatant'
import { fetchMyBattles } from './MyBattles/FetchMyBattles'
import { fetchCreateNewBattleSession } from './BattleSession/CreateNewBattleSession'
import { fetchBattleSession } from './BattleSession/FetchBattleSession'
import { fetchChallengers } from './Challengers/FetchChallengers'

import Combatant from '../Combatant'
import MyBattleSession from '../MyBattleSession';
import BattleSession from '../BattleSession';
import ChallengerBattleSession from '../ChallengerBattleSession';

import { IAppState } from './IAppState'


export function getOrCreateCombatant(): ThunkAction  {
    return (dispatch) => {
        fetchOrCreateCombatant().then((combatant: Combatant|null) => {
            dispatch(createGetOrCreateCombatantAction(combatant));
        })
    }
}

export function getMyBattles(combatantId: string): ThunkAction  {
    return (dispatch) => {
        fetchMyBattles(combatantId).then((battles: MyBattleSession[]|null) => {
            dispatch(createGetMyBattlesAction(battles));
        })
    }
}


export function updateMyBattleSession(updateBattleSession: MyBattleSession): ThunkActionGetState {
    return (dispatch, getState) => {
        let myBattles = getState().myBattlesState.myBattles?.slice();

        if (!myBattles) {
            myBattles = [];
        }

        let add=true;
        for(let i = 0; i < myBattles.length; i++) {
            if (myBattles[i].battleSessionId === updateBattleSession.battleSessionId) {
                myBattles[i] = updateBattleSession;
                add = false;
                break;
            }
        }

        if(add) {
            myBattles.unshift(updateBattleSession);
        }

        dispatch(createGetMyBattlesAction(myBattles));
    }
}

export function createNewBattleSession(combatantId: string, battlePath: string, language: string): ThunkAction  {
    return (dispatch) => {
        dispatch(createCreateNewBattleSessionStartAction(battlePath));
        fetchCreateNewBattleSession(combatantId, battlePath, language).then((battleSession: BattleSession|null) => {
            dispatch(createCreateNewBattleSessionFinishAction(battleSession));
        }).catch((err: Error) => {
            dispatch(createCreateNewBattleSessionErrorAction(err));
        });
    }
}

export function getBattleSession(combatantId: string, battleSessionId: string, language: string): ThunkAction  {
    return (dispatch) => {
        dispatch(createGetBattleSessionStartAction(battleSessionId));
        fetchBattleSession(combatantId, battleSessionId, language).then((battleSession: BattleSession|null) => {
            dispatch(createGetBattleSessionFinishAction(battleSession));
        }).catch((err: Error) => {
            dispatch(createGetBattleSessionErrorAction(err));
        });
    }
}

export function getChallengers(combatantId: string, battleId: string): ThunkAction  {
    return (dispatch) => {
        dispatch(createGetChallengersStartAction(battleId));
        fetchChallengers(combatantId, battleId).then((challengers: ChallengerBattleSession[]|null) => {
            dispatch(createGetChallengersFinishAction(challengers));
        }).catch((err: Error) => {
            dispatch(createGetChallengersErrorAction(err));
        });
    }
}



export enum ActionTypes {
    GET_OR_CREATE_COMBATANT = "GET_OR_CREATE_COMBATANT",
    GET_MY_BATTLES = "GET_MY_BATTLES",
    CREATE_NEW_BATTLE_SESSION_START = "CREATE_NEW_BATTLE_SESSION_START",
    CREATE_NEW_BATTLE_SESSION_ERROR = "CREATE_NEW_BATTLE_SESSION_ERROR",
    CREATE_NEW_BATTLE_SESSION_FINISH = "CREATE_NEW_BATTLE_SESSION_FINISH",
    GET_BATTLE_SESSION_START = "GET_BATTLE_SESSION_START",
    GET_BATTLE_SESSION_ERROR = "GET_BATTLE_SESSION_ERROR",
    GET_BATTLE_SESSION_FINISH = "GET_BATTLE_SESSION_FINISH",
    GET_CHALLENGERS_START = "GET_CHALLENGERS_START",
    GET_CHALLENGERS_ERROR = "GET_CHALLENGERS_ERROR",
    GET_CHALLENGERS_FINISH = "GET_CHALLENGERS_FINISH",
}


interface IGetOrCreateCombatantAction {
    type: typeof ActionTypes.GET_OR_CREATE_COMBATANT
    payload: Combatant|null
}

interface IGetMyBattlesAction {
    type: typeof ActionTypes.GET_MY_BATTLES
    payload: MyBattleSession[]|null
}

interface ICreateNewBattleSessionStartAction {
    type: typeof ActionTypes.CREATE_NEW_BATTLE_SESSION_START
    payload: string|null
}

interface ICreateNewBattleSessionErrorAction {
    type: typeof ActionTypes.CREATE_NEW_BATTLE_SESSION_ERROR
    payload: Error|null
}

interface ICreateNewBattleSessionFinishAction {
    type: typeof ActionTypes.CREATE_NEW_BATTLE_SESSION_FINISH
    payload: BattleSession|null
}

interface IGetBattleSessionStartAction {
    type: typeof ActionTypes.GET_BATTLE_SESSION_START
    payload: string|null
}

interface IGetBattleSessionErrorAction {
    type: typeof ActionTypes.GET_BATTLE_SESSION_ERROR
    payload: Error|null
}

interface IGetBattleSessionFinishAction {
    type: typeof ActionTypes.GET_BATTLE_SESSION_FINISH
    payload: BattleSession|null
}


interface IGetChallengersStartAction {
    type: typeof ActionTypes.GET_CHALLENGERS_START
    payload: string|null
}

interface IGetChallengersErrorAction {
    type: typeof ActionTypes.GET_CHALLENGERS_ERROR
    payload: Error|null
}

interface IGetChallengersFinishAction {
    type: typeof ActionTypes.GET_CHALLENGERS_FINISH
    payload: ChallengerBattleSession[]|null
}

function createGetOrCreateCombatantAction(combatant: Combatant|null) :IGetOrCreateCombatantAction  {
    return { payload: combatant, type: ActionTypes.GET_OR_CREATE_COMBATANT}
}

function createGetMyBattlesAction(battles: MyBattleSession[]|null) :IGetMyBattlesAction  {
    return { payload: battles, type: ActionTypes.GET_MY_BATTLES}
}

function createCreateNewBattleSessionStartAction(battlePath: string|null) :ICreateNewBattleSessionStartAction  {
    return { payload: battlePath, type: ActionTypes.CREATE_NEW_BATTLE_SESSION_START}
}

function createCreateNewBattleSessionErrorAction(error: Error|null) :ICreateNewBattleSessionErrorAction  {
    return { payload: error, type: ActionTypes.CREATE_NEW_BATTLE_SESSION_ERROR}
}

function createCreateNewBattleSessionFinishAction(battleSession: BattleSession|null) :ICreateNewBattleSessionFinishAction  {
    return { payload: battleSession, type: ActionTypes.CREATE_NEW_BATTLE_SESSION_FINISH}
}


function createGetBattleSessionStartAction(battleId: string|null) :IGetBattleSessionStartAction  {
    return { payload: battleId, type: ActionTypes.GET_BATTLE_SESSION_START}
}

function createGetBattleSessionErrorAction(error: Error|null) :IGetBattleSessionErrorAction  {
    return { payload: error, type: ActionTypes.GET_BATTLE_SESSION_ERROR}
}

function createGetBattleSessionFinishAction(battleSession: BattleSession|null) :IGetBattleSessionFinishAction  {
    return { payload: battleSession, type: ActionTypes.GET_BATTLE_SESSION_FINISH}
}

function createGetChallengersStartAction(battleId: string|null) :IGetChallengersStartAction  {
    return { payload: battleId, type: ActionTypes.GET_CHALLENGERS_START}
}

function createGetChallengersErrorAction(error: Error|null) :IGetChallengersErrorAction  {
    return { payload: error, type: ActionTypes.GET_CHALLENGERS_ERROR}
}

function createGetChallengersFinishAction(challengers: ChallengerBattleSession[]|null) :IGetChallengersFinishAction  {
    return { payload: challengers, type: ActionTypes.GET_CHALLENGERS_FINISH}
}


export type Action = IGetOrCreateCombatantAction
    |IGetMyBattlesAction
    |ICreateNewBattleSessionStartAction|ICreateNewBattleSessionErrorAction|ICreateNewBattleSessionFinishAction
    |IGetBattleSessionStartAction|IGetBattleSessionErrorAction|IGetBattleSessionFinishAction
    |IGetChallengersStartAction|IGetChallengersErrorAction|IGetChallengersFinishAction

    type Dispatch = (action: 
        Action) => any;

type getStateType = () => IAppState;

type ThunkAction = (dispatch: Dispatch) => any;
type ThunkActionGetState = (dispatch: Dispatch, getState: getStateType) => any;
