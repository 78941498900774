import BattleSession from '../../BattleSession';
import fetchBuilder from 'fetch-retry';

let fetchRetry = fetchBuilder(fetch);

export async function fetchBattleSession(combatantId: string, battleSessionId: string, codingLanguage: string) : Promise<BattleSession|null> {
    return fetchRetry('https://6y4r3q165d.execute-api.us-west-2.amazonaws.com/dev/battlesessions', {
      method: 'PUT',
        body: JSON.stringify({ codingLanguage: codingLanguage, battleId: battleSessionId, combatantId: combatantId }),
        headers: {'Content-Type': 'application.json'},
      }).then((battleSessionResponse) => {
        return battleSessionResponse.json();
      });
}