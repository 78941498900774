import { Reducer } from 'redux'
import { Action, ActionTypes } from '../actions'



import ICombatantState from './ICombatantState'
const defaultState: ICombatantState = {
  combatant: null,
}



const combatantReducer: Reducer<ICombatantState, Action> = (state: ICombatantState = defaultState, action: Action) => {
    switch (action.type) {
      case ActionTypes.GET_OR_CREATE_COMBATANT:
        return {
          ...state,
          combatant: action.payload,
        }
      default:
        return state
    }
  }

export default combatantReducer;