import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {RouteComponentProps, withRouter} from "react-router-dom";

function DrawerMenu({ history }: RouteComponentProps) {
    let _navMap = () => {
        history.push('/map');
    }
    let _navProfile = () => {
        history.push('/profile');
    }
    let _navRankings = () => {
        history.push('/rankings');
    }
    let _navMyBattles = () => {
        history.push('/');
    }
    let _navDisputes = () => {
        history.push('/shenanigans');
    }

    return (
        <div>
            <List>
                <ListItem button onClick={_navMyBattles}>
                    <ListItemText primary='reactbattle.net' />
                </ListItem>
                <div style={{ position: 'relative', width: '100%', height: '30px', fontSize: 'x-small' }}>
                    <div style={{ position: 'absolute', width: '100%', left: '0', top: '0' }}>
                        <div className='layout horizontal'>
                            <div className='flex'></div>
                            <span>Like React?  Try <a style={{ color: 'white' }} className='clickable' href='https://kotlinbattle.net'>Kotlin</a>!</span>
                        </div>
                    </div>
                </div>

                <ListItem button onClick={_navMyBattles}>
                    <ListItemText primary='My Battles' />
                </ListItem>
                <ListItem button onClick={_navMap}>
                    <ListItemText primary='Map' aria-label='Navigation - Open Map' />
                </ListItem>
                <ListItem button onClick={_navRankings}>
                    <ListItemText primary='Rankings' />
                </ListItem>
                <ListItem button onClick={_navProfile}>
                    <ListItemText primary='Profile' />
                </ListItem>
                <ListItem button onClick={_navDisputes}>
                    <ListItemText primary='Shenanigans!' />
                </ListItem>
            </List>
            <div>
                <img alt='awesome sword' height='200px' width='auto' src='/images/react-battle-logo.png' />
            </div>
        </div>
    );
}


export default withRouter(DrawerMenu);
