import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Combatant from '../Combatant'
import SiteData from '../SiteData'
import MyBattleSession from '../MyBattleSession'
import MapLocation from '../MapLocation'
import { RouteComponentProps } from "react-router";
import {
   withRouter
 } from 'react-router-dom'

class MyBattlesViewState {
    mapData = { mapName: 'React Land'};
    currentPath = 'start';
    currentTownName = 'Noob Town';
}

interface MyBattlesViewProps {
    combatant: Combatant;
    siteData: SiteData;
    myBattlesLoaded: boolean;
    myBattles: MyBattleSession[]|null;
    currentTown: MapLocation;
    mapLocations: MapLocation[];
}
 
class MyBattlesView extends Component<MyBattlesViewProps & RouteComponentProps, MyBattlesViewState> { 
  state = new MyBattlesViewState();
  
  render() {                                   
      return (
      <div className="card">
        <p>Welcome to <strong className='clickable'  onClick={this._launchMap}>{this.state.mapData.mapName}</strong>
         <strong> {this.props.combatant.username}</strong>!</p>
         <p>Here there is only combat of the purest form, death by a thousand cuts of {this.props.siteData.title} awaits...for your enemies!</p>
         <div>
          <p>You are currently in <strong>{this.props.currentTown.townName}</strong>, visit the <strong className='clickable' onClick={this._launchMap}>map</strong> to travel to a new town.</p>
          <Button variant="contained" onClick={this._launchMap}>Map</Button>
        </div>
        <div className='layout horizontal center'>
          <h1>Your Battles:</h1><div className='flex'></div><div><Button variant="contained" color="primary" onClick={this._createNewBattle}>New Battle</Button></div>
        </div>
        <div hidden={!this.props.myBattlesLoaded}>
          <p hidden={this.props.myBattles !== null && this.props.myBattles.length > 0}>You've got no battles yet!  Hit the <strong>New Battle</strong> button and join the fight club!</p>
        </div>
        <div hidden={this.props.myBattlesLoaded}>
          <span>Loading your battles...</span>
          <LinearProgress />
        </div>
        {this.props.myBattles && this.props.myBattles.map((item) => {
          return (
          <div className='layout horizontal center wrap' key={item.battleSessionId}>
            <div className='layout vertical'>
              <strong>{this._getTownName(item.battlePath, this.props.mapLocations)}</strong>&nbsp;
              <span style={{color: 'gray' }} hidden={item.battleStatus === "Completed"}>Started {this._getTimeSinceString(item.createTime)}</span>
              <span style={{color: 'gray' }} hidden={item.battleStatus === "Started"}>{this._getScoreDisposition(item)} in {this._getTimeDifferenceString(item.createTime, item.completeTime, "")}</span>
            </div>
            <div className='flex'></div>
            <div hidden={item.battleStatus === "Completed"}>
            <Button variant="contained"  color="secondary" style={{marginTop: '5px'}} onClick={this._launchBattle.bind(this, item)} 
              >Fight!</Button>
            </div>
            <div hidden={item.battleStatus === "Started" || !item.lastBattleTime}>
              <Button variant="contained" color="secondary" style={{marginTop: '5px', marginRight: '5px'}} onClick={this._launchChallengerDetails.bind(this, item)} 
                >Alert!</Button>
            </div>
            <div hidden={item.battleStatus === "Started"}>
              <Button variant="contained"  style={{marginTop: '5px'}} onClick={this._launchReview.bind(this, item)}>
                Results</Button>
            </div>
          </div>
          )
        })}
      </div>
      )
   }

   _getScoreDisposition(battleSession: MyBattleSession):string {
    if (battleSession.correctAnswers === 0) {
      return "You whiffed it"
    }
    if (battleSession.correctAnswers > 0 && battleSession.incorrectAnswers === 0 && battleSession.missedAnswers === 0) {
      return "You aced it"
    }
    let temp = "You got " + battleSession.correctAnswers + " right ";
    return temp;
  }

  _getTownName(battlePath: string, mapLocations: MapLocation[]):string {
    for (let i = 0; i < mapLocations.length; i++) {
      if (mapLocations[i].path === battlePath) {
        return mapLocations[i].townName;
      }
    }
    return mapLocations[0].townName;
  }
  
  _launchBattle(item:MyBattleSession) {
    this.props.history.push('/battle/' + item.battleId);
  }

  _launchChallengerDetails(item:MyBattleSession) {
    this.props.history.push('/challengers/' + item.battleId);
  }

  _launchReview(item:MyBattleSession) {
    this.props.history.push('/battle-result/' + item.battleId);
  }


  _calculateSecondsSince(startDate: string, endDate: string): number {
    let startTime = new Date(startDate);
    let currentTime = new Date(endDate);

    var secondsPassed = (currentTime.getTime() - startTime.getTime()) / 1000;
    if (secondsPassed < 0) {
      secondsPassed = 0;
    }
    return secondsPassed;
  }

  _getTimeSinceString(startDate: string): string {
    return this._getTimeDifferenceString(startDate, new Date().toISOString(), " ago");
  }
  
  _getTimeDifferenceString(startDate: string, endDate: string, postfix: string):string {
    var secondsRemaining = this._calculateSecondsSince(startDate, endDate);
    var yearsRemaining = Math.floor(secondsRemaining / (60 * 60 * 24 * 7 * 52));  

    if (!postfix) {
      postfix = '';
    }
    
    if (yearsRemaining > 0) {
      return yearsRemaining + " year" + (yearsRemaining > 1 ? "s" :"") + postfix;
    }

    secondsRemaining -= Math.floor(yearsRemaining * 60 * 60 * 24 * 7 * 52);

    secondsRemaining = secondsRemaining < 0 ? 0 : secondsRemaining;
    var monthsRemaining = Math.floor(secondsRemaining / (60 * 60 * 24 * 7 * 4.2));  

    if (monthsRemaining > 0) {
      return monthsRemaining + " month" + (monthsRemaining > 1 ? "s" :"") + postfix;
    }
    
    secondsRemaining -= Math.floor(monthsRemaining * 60 * 60 * 24 * 7 * 4.2);
    secondsRemaining = secondsRemaining < 0 ? 0 : secondsRemaining;
    var weeksRemaining = Math.floor(secondsRemaining / (60 * 60 * 24 * 7)); 

    if (weeksRemaining > 0) {
      return weeksRemaining + " week" + (weeksRemaining > 1 ? "s" :"") + postfix;
    }

    secondsRemaining -= weeksRemaining * 60 * 60 * 24 * 7;
    secondsRemaining = secondsRemaining < 0 ? 0 : secondsRemaining;
    var daysRemaining = Math.floor(secondsRemaining / (60 * 60 * 24));

    if (daysRemaining > 0) {
      return daysRemaining + " day" + (daysRemaining > 1 ? "s" :"") + postfix;
    }
    
    secondsRemaining -= daysRemaining * 60 * 60 * 24;
    secondsRemaining = secondsRemaining < 0 ? 0 : secondsRemaining;
    var hoursRemaining = Math.floor(secondsRemaining / (60 * 60));

    if (hoursRemaining > 0) {
      return hoursRemaining + " hour" + (hoursRemaining > 1 ? "s" :"") + postfix;
    }

    secondsRemaining -= hoursRemaining * 60 * 60;
    secondsRemaining = secondsRemaining < 0 ? 0 : secondsRemaining;
    var minutesRemaining = Math.floor(secondsRemaining / 60);

    if (minutesRemaining > 0) {
      return minutesRemaining + " minute" + (minutesRemaining > 1 ? "s" :"") + postfix;
    }

    secondsRemaining -= minutesRemaining * 60;
    secondsRemaining = secondsRemaining < 0 ? 0 : secondsRemaining;
    secondsRemaining = Math.floor(secondsRemaining % 60);

    if (secondsRemaining > 0) {
      return secondsRemaining + " second" + (secondsRemaining > 1 ? "s" :"") + postfix;
    }
    
    return "just now";
  }


   _launchMap = () => {
      this.props.history.push('/map');
   }
   _createNewBattle() {
      window.dispatchEvent(new CustomEvent('create-new-battle'));
   }
  }

  export default withRouter(MyBattlesView);