class MapLocation {
    path = ''
    townName = ''
    x = ''
    y = ''
    adjacents=['']
    title = ''
    recommendedNext = ''
  
    public constructor(init?:Partial<MapLocation>) {
      Object.assign(this, init);
    }  
  }

export default MapLocation;