import { Reducer } from 'redux'
import { Action, ActionTypes } from '../actions'



import IBattleSessionState from './IBattleSessionState'
const defaultState: IBattleSessionState = {
  battleSession: null,
  battleSessionError: null,
  battlePath: null,
}



const battleSessionReducer: Reducer<IBattleSessionState, Action> = (state: IBattleSessionState = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.CREATE_NEW_BATTLE_SESSION_FINISH:
      return {
        ...state,
        battleSession: action.payload,
      }
    case ActionTypes.CREATE_NEW_BATTLE_SESSION_START:
      return {
        ...state,
        battlePath: action.payload,
        battleSession: null,
        battleSessionError: null,
      }
    case ActionTypes.CREATE_NEW_BATTLE_SESSION_ERROR:
      return {
        ...state,
        battleSessionError: action.payload,
      }
    case ActionTypes.GET_BATTLE_SESSION_FINISH:
      return {
        ...state,
        battleSession: action.payload,
      }
    case ActionTypes.GET_BATTLE_SESSION_START:
      return {
        ...state,
        battleSessionError: null,
        battleSession: null,
        battlePath: action.payload,
      }
    case ActionTypes.GET_BATTLE_SESSION_ERROR:
      return {
        ...state,
        battleSessionError: action.payload,
      }
    default:
      return state
  }
}

export default battleSessionReducer;