import BattleSession from '../../BattleSession';
import fetchBuilder from 'fetch-retry';

let fetchRetry = fetchBuilder(fetch);

export async function fetchCreateNewBattleSession(combatantId: string, battlePath: string, codingLanguage: string) : Promise<BattleSession|null> {
    return fetchRetry('https://6y4r3q165d.execute-api.us-west-2.amazonaws.com/dev/battles', {
        method: 'POST',
        body: JSON.stringify({ battlePath: battlePath, combatantId: combatantId, codingLanguage: codingLanguage }),
        headers: {'Content-Type': 'application.json'},
      }).then((newBattleSessionResponse) => {
        return newBattleSessionResponse.json();
      });
}