import Combatant from '../../Combatant'
import fetchBuilder from 'fetch-retry';

let fetchRetry = fetchBuilder(fetch);


export async function fetchOrCreateCombatant() : Promise<Combatant|null> {
    let combatantId = null;
    let combatantString = localStorage.getItem('combatant')
    if (combatantString === null || combatantString === 'null' || combatantString === 'undefined' || combatantString === undefined) {
    }
    else {
      let combatant = JSON.parse(combatantString);
      combatantId = combatant?.combatantId;
    }

    let postData = { combatantId: combatantId, siteLanguage: 'React' };
    return fetchRetry('https://6y4r3q165d.execute-api.us-west-2.amazonaws.com/dev/combatants', {
      method: 'PUT',
      body: JSON.stringify(postData),
      headers: { 'Content-Type': 'text/plain' },
    }).then((apiResponse: any) => {
      return new Promise<Combatant|null>((resolve, reject) => {
        apiResponse.json().then((combatant:any) => {
          localStorage.setItem('combatant', JSON.stringify(combatant));
          resolve(combatant as Combatant|null);
        }).catch((err:any) => {
          reject(err);
        });
      });
    });

    /*let query = '{combatant(combatantId: "' + combatantId + '"){username combatantId, createTime, xp}}';

    return fetchRetry('https://6y4r3q165d.execute-api.us-west-2.amazonaws.com/dev/query?query=' + encodeURI(query), 
    {
      method: 'GET',
      headers: { 'Accept': 'application/text' },
    }).then((apiResponse: any) => {
      return new Promise<Combatant|null>((resolve, reject) => {
        apiResponse.json().then((responseData:any) => {
          let combatant = responseData.data.combatant as Combatant | null;
          localStorage.setItem('combatant', JSON.stringify(combatant));
          resolve(combatant);
        }).catch((err:any) => {
          reject(err);
        });
      });
    }); */
}