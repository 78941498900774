import React from 'react';

class FightTransitionProps {
  showFightDiv: boolean = false;
  fightImage: string = "";
  fightImageWidth: number = 0;
}

function FightTransition({showFightDiv, fightImage, fightImageWidth}: FightTransitionProps) {
    const [countDown, setCountDown] = React.useState(5);
    const [countDownTimerId, setCountDownTimerId] = React.useState<ReturnType<typeof setInterval>|null>(null);

    React.useEffect(() => {
      if (!showFightDiv && countDownTimerId) {
        clearInterval(countDownTimerId);
        setCountDownTimerId(null);
      }
      else if(showFightDiv && !countDownTimerId) {
        let cd = 5;
        let id = setInterval(() => {
          if (cd > 1) {
            cd -= 1;
          }
          else if (cd > 0.5) {
            cd -= 0.5;
          }
          else if (cd > 0.1) {
            cd = Math.floor((cd - 0.1)*100)/100;
          }
          else if (cd > 0.01) {
            cd = Math.floor((cd - 0.01)*1000)/1000;
          }
          else {
            cd -= countDown*.01;
          }
    
          setCountDown(() => {
            return cd;
          });          
        }, 1000);
        setCountDownTimerId(id);
        setCountDown(5);
      }

      return function cleanup() {
        if (countDownTimerId && !showFightDiv) {
          clearInterval(countDownTimerId);
          setCountDownTimerId(null);
        }
      }
    }, [showFightDiv, countDown, countDownTimerId]);
    
    return (
      <div id='fightDiv' hidden={!showFightDiv} style={{position: 'absolute', width: '100%', height: '100%', top: '0px', left: '0px', zIndex: 10000, backgroundColor: 'black'}}>
      <div className='layout horizontal center'>
        <div className='layout vertical center' style={{width: '100%'}}>
          <h2>Fight in...</h2>
          <div style={{position: 'relative', width: '200px', height: '200px'}}>
            <div className='spin-circle' style={{position:'absolute'}}>
            </div>
            <div className='layout vertical center' style={{width: '200px', height: '200px'}}>
              <div className='layout horizontal center' style={{zIndex: 1000, height: '200px'}}>
                <span style={{fontSize: '100px'}}>{countDown}</span>
              </div>
            </div>
          </div>
          <img alt='crossed swords of react doom!' src={fightImage} className='resize' style={{maxWidth: fightImageWidth + 'px'}} />
        </div>
      </div>
    </div>
    );
}

export default FightTransition;
