import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DrawerMenu from './DrawerMenu'
import LinearProgress from '@material-ui/core/LinearProgress';
import {computeLevelFromXp, calculateXpProgress} from './Combatant';
import Combatant from './Combatant';



const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

class ResponsiveDrawerProps {
    combatant: Combatant | null = null;
}

function ResponsiveDrawer(props: React.PropsWithChildren<ResponsiveDrawerProps>) {
  const {children, combatant} = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const mobileDrawer = (<div tabIndex={0}
    role="button"
    onClick={handleDrawerToggle}
    onKeyDown={handleDrawerToggle}>
    <DrawerMenu/>
  </div>);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className='layout horizontal center' style={{width:'100%'}}>
            <div className='flex'></div>
            <div className='layout vertical center'>
              <span style={{color:'gray', fontSize: 'x-small'}}>xp to next level</span>
              <LinearProgress variant='determinate' color='secondary' style={{width:'100px'}} value={combatant ? calculateXpProgress(combatant.xp) : 0} ></LinearProgress>
            </div>
            <span style={{marginLeft: '10px'}}>{combatant ? computeLevelFromXp(combatant.xp) : 0}</span>
            <div className='flex'></div>
            <strong className='username'>{combatant ? combatant.username : 'loading'}</strong>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="menu">
        <Hidden smUp implementation="css" >
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, 
            }}
          >
            <div>
            <div className={classes.toolbar} />
            <Divider />
            {mobileDrawer}
            
          </div>
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            
            variant="permanent"
            open
          >
            <DrawerMenu />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}



export default ResponsiveDrawer;