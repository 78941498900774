import React, { Component } from 'react';
import fetchBuilder from 'fetch-retry';

let fetchRetry = fetchBuilder(fetch);



class ErrorHandler extends Component<{}, {}> {
    componentDidMount() {
        window.addEventListener('error', this.onError.bind(this));
    }
    onError(e: any) {
        this.componentDidCatch(e.error, e);
    }
    _getStackString(stack: string): string {
        let strippedStack = "";
        try {
            if (stack.length < 100000) {
                return stack;
            }
            else {

                let splitStrings = stack.split('\n');
                let regex = /(.+)(\(data:text\/javascript;).+(%2F%2F%23%20sourceURL%3Dhttp)(.+$)/;
                for (let i = 0; i < splitStrings.length; i++) {
                    let ok = false;
                    try {
                        if (splitStrings[i].length > 501 && regex.test(splitStrings[i])) {
                            strippedStack += splitStrings[i].replace(regex, "$1 $3$4 (Source Removed)");
                            strippedStack += '\n';
                        }
                        else {
                            strippedStack += splitStrings[i] + '\n';
                        }
                        ok = true;
                    }
                    catch (err3) {
                        ok = false;
                    }
                    if (!ok) {
                        if (splitStrings[i].length > 501) {
                            try {
                                strippedStack += splitStrings[i].substring(0, 501) + decodeURIComponent(splitStrings[i].substring(splitStrings[i].length - 501)) + '\n';
                            }
                            catch (err2) {
                                strippedStack += splitStrings[i].substring(0, 501) + splitStrings[i].substring(splitStrings[i].length - 501) + '\n';
                            }
                        }
                        else {
                            strippedStack += splitStrings[i] + '\n';
                        }
                    }
                }
                if (strippedStack.length < 100000) {
                    return strippedStack;
                }
                return "[Stack trace was too long to include, > 100k][Partial Stack: " + strippedStack.substring(0, 1000);
            }
        }
        catch (err) {
            let errorString = "[Stack trace parsing error: " + JSON.stringify(err) + "]";
            if (strippedStack.length > 0 && strippedStack.length < 100000) {
                errorString += '[Partial Trace: ' + strippedStack + ']';
            }
            else {
                errorString += '[Partial Trace: ' + strippedStack.substring(0, 1000) + ']';
            }
            return errorString;
        }
    }

    componentDidCatch(error: any, errorInfo: any) {
        let userDetails = "Unknown Battle Id";
        let errorMessage = 'Unknown Error Message';
        if (error.message) {
            errorMessage = error.message;
        }

        let fileName = "";
        if (error.filename) {
            fileName = error.filename;
        }

        let lineNo = -1;
        if (error.lineno !== undefined) {
            lineNo = error.lineno;
        }
        let stack = "No Call Stack";
        if (error.stack) {
            stack = this._getStackString(error.stack);
        }

        if (errorInfo.componentStack) {
            stack += 'Component Stack: \n' + errorInfo.componentStack;
        }

        /*if (this.props.combatant) {
            userDetails = this.props.combatant.combatantId + "-" + this.props.combatant.username;
        }*/

        let name = 'UnknownErrorName';

        if (error.name) {
            name = error.name;
        }


        let errorDetails = {
            battleId: userDetails,
            fileName: fileName,
            lineNo: lineNo,
            stackTrace: stack,
            message: errorMessage,
            name: name,
            site: "reactbattle.net",
        };

        fetchRetry('https://6y4r3q165d.execute-api.us-west-2.amazonaws.com/dev/errors', {
            method: 'POST',
            body: JSON.stringify(errorDetails),
            headers: { 'Content-Type': 'application.json' },
        }).then(() => {
        });
    }
    render() {
        return (<div>{this.props.children}</div>);
    }
}

export default ErrorHandler;
