import fetchBuilder from 'fetch-retry';
import ChallengerBattleSession from '../../ChallengerBattleSession';

let fetchRetry = fetchBuilder(fetch);

export async function fetchChallengers(combatantId: string, battleId: string) : Promise<ChallengerBattleSession[]|null> {
    return fetchRetry('https://6y4r3q165d.execute-api.us-west-2.amazonaws.com/dev/battlesessions/others', {
        method: 'PUT',
        body: JSON.stringify({ combatantId: combatantId, battleId: battleId }),
        headers: {'Content-Type': 'application.json'},
      }).then((apiResponse) => {
          return apiResponse.json();
      });
}