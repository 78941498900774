class Combatant {
    combatantId: string; 
    username: string;
    createTime: Date;
    xp: number;

    public constructor(combatantId: string, username: string, createTimeString: string, xp: number) {
        this.xp = xp
        this.combatantId = combatantId
        this.username = username
        this.createTime = new Date(createTimeString)
    }

}

export function computeLevelFromXp(xp: number): number {
    if (!xp) {
        xp = 0;
    }
    let level = 1;
    let power = 0;
    while (xp > 0) {
        xp -= Math.pow(2, power);
        power++;
        if (xp > 0) {
            level++;
        }
    }
    return level;
}

export function calculateXpProgress(xp: number): number {
    let level = computeLevelFromXp(xp);
    let lastLevelXp = Math.pow(2, level-1);
    let nextXp = Math.pow(2, level);
    let xpDelta = xp - lastLevelXp;
    let progressPct = xpDelta/(nextXp - lastLevelXp);
    return progressPct*100;
  }
  

export default Combatant;