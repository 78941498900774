import { 
    applyMiddleware, 
    combineReducers, 
    createStore, 
    Store 
  } from 'redux'


import logger from 'redux-logger'
import thunk from 'redux-thunk'

import combatantReducer from './Combatant/reducer'
import myBattlesReducer from './MyBattles/reducer'
import battleSessionReducer from './BattleSession/reducer'
import challengersState from './Challengers/reducer'

import {IAppState} from './IAppState'
import {Action} from './actions'

const rootStore = combineReducers({
    combatantState: combatantReducer,
    myBattlesState: myBattlesReducer,
    battleSessionState: battleSessionReducer,
    challengersState: challengersState,
  });


export function createAppStore(): Store<IAppState, Action> {
    return applyMiddleware(thunk, logger)(createStore)(rootStore)
}