import { Reducer } from 'redux'
import { Action, ActionTypes } from '../actions'



import IGetChallengersState from './IGetChallengersState'
const defaultState: IGetChallengersState = {
  challengers: null,
  challengersBattleId: null,
  challengersError: null,
}



const getChallengersReducer: Reducer<IGetChallengersState, Action> = (state: IGetChallengersState = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.GET_CHALLENGERS_FINISH:
      return {
        ...state,
        challengers: action.payload,
      }
    case ActionTypes.GET_CHALLENGERS_START:
      return {
        ...state,
        challengersBattleId: action.payload,
        challengers: null,
        challengersError: null,
      }
    case ActionTypes.GET_CHALLENGERS_ERROR:
      return {
        ...state,
        challengersError: action.payload,
      }
    default:
      return state
  }
}

export default getChallengersReducer;