enum UIStateEnum {
    LoadOrCreateCombatant,
    LoadingCombatant,
    LoadMyBattles,
    LoadingMyBattles,
    MyBattles,
    LoadExistingBattle,
    LoadingExistingBattle,
    StartBattle,
    StartingBattle,
    Battle,
    LoadBattleResult,
    LoadingBattleResult,
    BattleResult,
    NotWorthy,
    LoadChallengers,
    LoadingChallengers,
    Challengers,    
    Shenanigans,
    Profile,
    Rankings,
    Map,
}

export default UIStateEnum;