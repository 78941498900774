import MyBattleSession from '../../MyBattleSession';
import fetchBuilder from 'fetch-retry';

let fetchRetry = fetchBuilder(fetch);

export async function fetchMyBattles(combatantId: string) : Promise<MyBattleSession[]|null> {
    let postData = { combatantId: combatantId, siteLanguage: 'React' };

    return fetchRetry('https://6y4r3q165d.execute-api.us-west-2.amazonaws.com/dev/battles', {
        method: 'PUT',
        body: JSON.stringify(postData),
        headers: {'Content-Type': 'application.json'},
      }).then((apiResponse) => {
          return apiResponse.json();
      });
}