import { Reducer } from 'redux'
import { Action, ActionTypes } from '../actions'



import IMYBattlesState from './IMyBattlesState'
const defaultState: IMYBattlesState = {
  myBattles: null,
}



const myBattlesReducer: Reducer<IMYBattlesState, Action> = (state: IMYBattlesState = defaultState, action: Action) => {
    switch (action.type) {
      case ActionTypes.GET_MY_BATTLES:
        return {
          ...state,
          myBattles: action.payload,
        }
      default:
        return state
    }
  }

export default myBattlesReducer;